import { defineStore } from 'pinia';
import { useNuxtApp } from "#app";
import { RecipeCategoryCollectionType } from "~/types/recipe/RecipeCategoryCollectionType";
import { RecipeCategoryType } from "~/types/recipe/RecipeCategoryType";

export const useRecipeCategoryCollectionStore = defineStore({
    id: 'recipeCategoryCollection',
    state: (): RecipeCategoryCollectionType => ({
        list: undefined,
        error: undefined,
    }),
    getters: {
        getFilter(state: RecipeCategoryCollectionType) {
            if (!state.list) {
                return [];
            }

            return [{
                name: 'All categories',
                href: '/recipes',
                slug: 'all',
            }].concat(
                state.list.map(item => ({
                    name: item.title,
                    href: item.url,
                    slug: item.slug,
                }))
            );
        }
    },
    actions: {
        async fetchList() {
            if (this.list !== undefined) {
                return true;
            }

            try {
                const fields = ['title', 'slug', 'svg_icon'];
                const sort = ['sorting:desc'];
                const response = await useNuxtApp().axios.get('/recipe-categories', {
                    params: {
                        fields,
                        sort,
                    }
                });
                if (response?.data?.data === undefined) {
                    this.error = createError({ statusCode: 404, statusMessage: 'Page Not Found' });
                    return false;
                }

                if (!response?.data?.data.length) {
                    this.list = [];
                    this.error = `There are no posts on page ${page}. Don\'t bully, use the paginator.`;
                    return false;
                }

                this.list = response?.data?.data.map(item => <RecipeCategoryType>({
                    title: item?.attributes?.title,
                    slug: item?.attributes?.slug,
                    svgInline: item?.attributes?.svg_icon,
                    url: `/recipes/category-${item?.attributes?.slug}`
                }));
                this.error = undefined;
            } catch (error) {
                console.log(error);
                this.error = createError({ statusCode: 500, statusMessage: 'Internal error' });
            }

            return true;
        }
    },
});
